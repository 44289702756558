// src/Category.js
import React, { useState,useEffect,useRef } from 'react'
import { useParams } from 'react-router-dom'
import './Category.css'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import CategoryBox from '../Components/Categorybox'
import categoryData from '../Content/CategoryData'


const Category = () => {
  const { id } = useParams()
  const category = categoryData[id]
  const [filteredItems, setFilteredItems] = useState(category.items)
  const [showGenderDropdown, setShowGenderDropdown] = useState(false)
  const [showPriceDropdown, setShowPriceDropdown] = useState(false)

  useEffect(() => {
    if (category) {
      setFilteredItems(category.items)
    }
  }, [category])

    const handleGenderButtonClick = () => {
      setShowGenderDropdown(!showGenderDropdown)
    }

    const handlePriceButtonClick = () => {
      setShowPriceDropdown(!showPriceDropdown)
    }

const handleGenderFilter = (gender) => {
  if (gender === 'All') {
    setFilteredItems(category.items)
  } else {
    const filtered = category.items.filter(
      (item) => item.gender === gender || item.gender === 'Unisex'
    )
    setFilteredItems(filtered)
  }
  setShowGenderDropdown(false)
}


  const handlePriceSort = (order) => {
    let sorted = []
    if (order === 'low-to-high') {
      sorted = [...filteredItems].sort((a, b) => a.price - b.price)
    } else if (order === 'high-to-low') {
      sorted = [...filteredItems].sort((a, b) => b.price - a.price)
    } else if (order === 'latest') {
      sorted = [...filteredItems].sort((a, b) => b.id - a.id) // Sort by id in descending order
    }
    setFilteredItems(sorted)
    setShowPriceDropdown(false)
  }

  const genderDropdownRef = useRef(null)
  const priceDropdownRef = useRef(null)

  // Close gender dropdown if clicked outside
  const handleClickOutsideGender = (event) => {
    if (
      genderDropdownRef.current &&
      !genderDropdownRef.current.contains(event.target)
    ) {
      setShowGenderDropdown(false)
    }
  }

  // Close price dropdown if clicked outside
  const handleClickOutsidePrice = (event) => {
    if (
      priceDropdownRef.current &&
      !priceDropdownRef.current.contains(event.target)
    ) {
      setShowPriceDropdown(false)
    }
  }

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutsideGender)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideGender)
    }
  }, [])

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutsidePrice)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePrice)
    }
  }, [])

  if (!category) {
    return <div>Category not found</div>
  }

  return (
    <>
      <Navbar />
      <div className="category-page">
        <h2>{category.title}</h2>
        <div className="filter-buttons">
          <div className="dropdown-overlay-c" ref={genderDropdownRef}>
            <div className="filter-text-c" onClick={handleGenderButtonClick}>
              Gender &#8595;
              <div
                className={`dropdown-menu-c ${
                  showGenderDropdown ? 'show' : ''
                }`}
              >
                <div onClick={() => handleGenderFilter('All')}>All</div>
                <div onClick={() => handleGenderFilter('Male')}>Male</div>
                <div onClick={() => handleGenderFilter('Female')}>Female</div>
              </div>
            </div>
          </div>
          <div className="dropdown-overlay-c" ref={priceDropdownRef}>
            <div className="filter-text-c" onClick={handlePriceButtonClick}>
              Price &#8595;
              <div
                className={`dropdown-menu-c ${showPriceDropdown ? 'show' : ''}`}
              >
                <div onClick={() => handlePriceSort('latest')}>Latest</div>
                <div onClick={() => handlePriceSort('low-to-high')}>
                  Low to High
                </div>
                <div onClick={() => handlePriceSort('high-to-low')}>
                  High to Low
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="category-items">
          {filteredItems.map((item) => (
            <CategoryBox
              key={item.id}
              id={item.id}
              pic={item.pic}
              pic1={item.pic1}
              gender={item.gender}
              price={item.price}
              categoryId={id}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Category
