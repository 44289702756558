import Homepage from "./Webpages/Homepage";
import Category from "./Webpages/Category";
import Outfit from "./Webpages/Outfit";
import About from "./Webpages/About";
import NotFound from "./Webpages/Notfound";
import ScrollToTop from "./Components/ScrollToTop"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/categories/:id" element={<Category />} />
          <Route path="/category/:categoryId/:itemId" element={<Outfit />} />
          <Route path="/about" element={<About/>} />
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </Router>
    </div>
  )
}

export default App;
