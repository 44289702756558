import academiaItems from './Academia'
import koreanItems from './Korean'
import glamorousItems from './Glamorous'
import cottageItems from './Cottage'
import streetItems from './Street'

const categoryData = {
  a: {
    title: 'Academia',
    items: academiaItems,
  },
  b: {
    title: 'Korean',
    items: koreanItems,
  },
  c: {
    title: 'Cottage Core',
    items: cottageItems,
  },
  d: {
    title: 'Street',
    items: streetItems,
  },
  e: {
    title: 'Glamorous',
    items: glamorousItems,
  },
}
export default categoryData
