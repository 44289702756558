const glamorousItems = [
  {
    id: '1',
    pic: 'https://m.media-amazon.com/images/I/71UNWyIP7sL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71qkJ0yWL6L._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71kZTq96VNL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/815CoL4NX1L._SY695_.jpg',
    gender: 'Female',
    price: 2447,
    price1: 1598,
    price2: 849,
    link1: 'https://amzn.to/463UlzP',
    link2: 'https://amzn.to/3znaFzl',
  },
  {
    id: '2',
    pic: 'https://m.media-amazon.com/images/I/61qJ4PW4JWL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61N9rw-BVpL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/511KCMhmWYL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51YCE5TrUWL._SX679_.jpg',
    gender: 'Male',
    price: 1994 + 379,
    price1: 1994,
    price2: 379,
    link1: 'https://amzn.to/45R1m6W',
    link2: 'https://amzn.to/3RPmuo1',
  },
  {
    id: '3',
    pic: 'https://m.media-amazon.com/images/I/71e4FUIRvTL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51cHuDTXTWL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/711QSsj6q1L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51XnMiWDxxL._SY695_.jpg',
    gender: 'Female',
    price: 1964,
    price1: 1469,
    price2: 495,
    link1: 'https://amzn.to/4eIbnr1',
    link2: 'https://amzn.to/3RRQIa1',
  },
  {
    id: '4',
    pic: 'https://m.media-amazon.com/images/I/711o-vCn3yL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61qq0sWF8zL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81N-DdxsZcL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/81fL3rPkUEL._SX569_.jpg',
    gender: 'Male',
    price: 854 + 719,
    price1: 854,
    price2: 719,
    link1: 'https://amzn.to/4d9TXlQ',
    link2: 'https://amzn.to/4fE6lfK',
  },
  {
    id: '5',
    pic: 'https://m.media-amazon.com/images/I/61faEi0RiKL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/91nDm3eybgL._SX695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71munGj3amL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/A1FAg4i7REL._SX695_.jpg',
    gender: 'Female',
    price: 2251,
    price1: 1653,
    price2: 598,
    link1: 'https://amzn.to/3XKSI7F',
    link2: 'https://amzn.to/3XMlBjT',
  },
  {
    id: '6',
    pic: 'https://m.media-amazon.com/images/I/71Hz6vEgkPL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61IKX0vG6bL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71VzHhZlmWL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61E+3B4RhPL._SY741_.jpg',
    gender: 'Male',
    price: 399 + 499,
    price1: 399,
    price2: 499,
    link1: 'https://amzn.to/4eYTRz1',
    link2: 'https://amzn.to/3VOWAlK',
  },
  {
    id: '7',
    pic: 'https://m.media-amazon.com/images/I/71sP2xxsOQL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/91EFv9OcPRL._SX695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71eWF7gzecL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/9124Sw9oftL._SX695_.jpg',
    gender: 'Female',
    price: 2038,
    price1: 1259,
    price2: 779,
    link1: 'https://amzn.to/3VPML75',
    link2: 'https://amzn.to/3VKsfos',
  },
  {
    id: '8',
    pic: 'https://m.media-amazon.com/images/I/61eBAD3B5YL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61mGzlj8JoL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/714bdo0IljL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61cgpCE7mML._SX679_.jpg',
    gender: 'Male',
    price: 499 + 383,
    price1: 499,
    price2: 383,
    link1: 'https://amzn.to/3XMlJ2R',
    link2: 'https://amzn.to/4cJ2Nq1',
  },
  {
    id: '9',
    pic: 'https://m.media-amazon.com/images/I/41R2dOgSgKL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/6122e81JCdL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/41MTDFmpGWL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51xn8L+pXIL._SY695_.jpg',
    gender: 'Female',
    price: 1558,
    price1: 659,
    price2: 899,
    link1: 'https://amzn.to/3xQgNiV',
    link2: 'https://amzn.to/3VKXcbW',
  },
  {
    id: '10',
    pic: 'https://m.media-amazon.com/images/I/51Sge544W0L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/5171GJnOgGL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51GzoHNuO9L._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/6155uInEJhL._SY741_.jpg',
    gender: 'Male',
    price: 397 + 385,
    price1: 397,
    price2: 385,
    link1: 'https://amzn.to/3VOWOJC',
    link2: 'https://amzn.to/3VEGvic',
  },
  {
    id: '11',
    pic: 'https://m.media-amazon.com/images/I/614TAnoDEmL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81BYoyKCrXS._SX695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61jLdjn7hUL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/91m1z4BFTFS._SX695_.jpg',
    gender: 'Female',
    price: 599 + 368,
    price1: 599,
    price2: 368,
    link1: 'https://amzn.to/3XMXOR8',
    link2: 'https://amzn.to/4eLbfqv',
  },
  {
    id: '12',
    pic: 'https://m.media-amazon.com/images/I/31n9e4Ju+UL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/515olk-XS-L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/41p2hubrkYL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51q-5EHRqlL._SY741_.jpg',
    gender: 'Male',
    price: 539 + 649,
    price1: 539,
    price2: 649,
    link1: 'https://amzn.to/4eOqyPh',
    link2: 'https://amzn.to/3XGmV85',
  },
  {
    id: '13',
    pic: 'https://m.media-amazon.com/images/I/71nYn-P2EjL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61yBYcIWbLL._SX695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61HExqQsudL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/618s9GWN+QL._SY695_.jpg',
    gender: 'Female',
    price: 799 + 595,
    price1: 799,
    price2: 595,
    link1: 'https://amzn.to/3VNDKez',
    link2: 'https://amzn.to/45NPnXu',
  },
  {
    id: '14',
    pic: 'https://m.media-amazon.com/images/I/71hx1gwSE2L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51kusD+mE9L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/717EUf5wycL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/41oYM23wK4L._SY741_.jpg',
    gender: 'Male',
    price: 595 + 609,
    price1: 595,
    price2: 609,
    link1: 'https://amzn.to/3Lb0Irg',
    link2: 'https://amzn.to/4bt76EU',
  },
  {
    id: '15',
    pic: 'https://m.media-amazon.com/images/I/61opZrzUSJL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/914u3vNFKZL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81jfQqvHDxL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/917s4VaCGML._SY695_.jpg',
    gender: 'Female',
    price: 799 + 498,
    price1: 799,
    price2: 498,
    link1: 'https://amzn.to/3xIKF0M',
    link2: 'https://amzn.to/3RRPlYY',
  },
  {
    id: '16',
    pic: 'https://m.media-amazon.com/images/I/51VWgC0tRaL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51LThp+U14L._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51kZx7s4LZL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51b9qnBuRpL._SX679_.jpg',
    gender: 'Male',
    price: 2157 + 275,
    price1: 2157,
    price2: 275,
    link1: 'https://amzn.to/4buCTFx',
    link2: 'https://amzn.to/4cLY1rZ',
  },
  {
    id: '17',
    pic: 'https://m.media-amazon.com/images/I/81J6Wo0OcuL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/6179CUKZThL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81uNkv1TYwL._SY879_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/617eNPKwiIL._SY695_.jpg',
    gender: 'Female',
    price: 493 + 259,
    price1: 493,
    price2: 259,
    link1: 'https://amzn.to/3xL3rEG',
    link2: 'https://amzn.to/3Lartw7',
  },
  {
    id: '18',
    pic: 'https://m.media-amazon.com/images/I/61mgXT5pBWL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61dtFyH9QsL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71D-DBlQUmL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61T5QmztLVL._SY741_.jpg',
    gender: 'Male',
    price: 1499 + 399,
    price1: 1499,
    price2: 399,
    link1: 'https://amzn.to/4cQtA3I',
    link2: 'https://amzn.to/3xLMxFU',
  },
  {
    id: '19',
    pic: 'https://m.media-amazon.com/images/I/41tzdmmIomL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71ZUkkF1vsL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/41uD7jWJhoL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71zrwL+PwtL._SY695_.jpg',
    gender: 'Female',
    price: 339 + 240,
    price1: 339,
    price2: 240,
    link1: 'https://amzn.to/3WarbeU',
    link2: 'https://amzn.to/3XVv7Bo',
  },
  {
    id: '20',
    pic: 'https://m.media-amazon.com/images/I/51c2AqsOr7L.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61thZvRxdWL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51zS6HYzj2L.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61uX+Y4LADL._SY741_.jpg',
    gender: 'Male',
    price: 1999 + 399,
    price1: 1999,
    price2: 399,
    link1: 'https://amzn.to/4bxqh0q',
    link2: 'https://amzn.to/3xKN4rO',
  },
  {
    id: '21',
    pic: 'https://m.media-amazon.com/images/I/71FAFkzQ+ML._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61vramTKVLL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71HuumQXYEL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71RCSsz5T5L._SY695_.jpg',
    gender: 'Female',
    price: 379 + 473,
    price1: 379,
    price2: 473,
    link1: 'https://amzn.to/4bxGDGh',
    link2: 'https://amzn.to/3zAIahF',
  },
  {
    id: '22',
    pic: 'https://m.media-amazon.com/images/I/715JTJItzuL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61ernybOw6L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71LsOioK8lL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61T9zpIvpSL._SY741_.jpg',
    gender: 'Male',
    price: 499 + 399,
    price1: 499,
    price2: 399,
    link1: 'https://amzn.to/3VWaRgz',
    link2: 'https://amzn.to/4cSP54c',
  },
  {
    id: '23',
    pic: 'https://m.media-amazon.com/images/I/71Ay7UfasOL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81clMNDx5NL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61Ab4mOajgL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71TIxXk7ABL._SY695_.jpg',
    gender: 'Female',
    price: 708 + 659,
    price1: 708,
    price2: 659,
    link1: 'https://amzn.to/4bzYxIv',
    link2: 'https://amzn.to/4eSwSFB',
  },
  {
    id: '24',
    pic: 'https://m.media-amazon.com/images/I/51-d3Fw-FiS._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81DaozTSVxL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51qq4DM0SrL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/A1OMxe5sh9L._SX569_.jpg',
    gender: 'Male',
    price: 2100 + 366,
    price1: 2100,
    price2: 366,
    link1: 'https://amzn.to/3VZEWM0',
    link2: 'https://amzn.to/4cyEThc',
  },
  {
    id: '25',
    pic: 'https://m.media-amazon.com/images/I/51JljK-68NL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51cdDJNhpZL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/517hpgzilOL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/516BCJmSgzL._SY625_.jpg',
    gender: 'Female',
    price: 499 + 149,
    price1: 499,
    price2: 149,
    link1: 'https://amzn.to/3WnVv60',
    link2: 'https://amzn.to/460HJsU',
  },
  {
    id: '26',
    pic: 'https://m.media-amazon.com/images/I/51l8sJPhGAL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51NH+WqYQ+L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61VP2-YuYzL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51NRWMMs8RL._SY741_.jpg',
    gender: 'Male',
    price: 598 + 849,
    price1: 598,
    price2: 849,
    link1: 'https://amzn.to/4bGXE0V',
    link2: 'https://amzn.to/4cFfl22',
  },
  {
    id: '27',
    pic: 'https://m.media-amazon.com/images/I/81k4Um1OavL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41eHnnNoukL.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71pm+Bri6vL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51QrVYmk0HL._SY625_.jpg',
    gender: 'Female',
    price: 2039 + 70,
    price1: 2039,
    price2: 70,
    link1: 'https://amzn.to/4bJkSUb',
    link2: 'https://amzn.to/4bP6UjK',
  },
  {
    id: '28',
    pic: 'https://m.media-amazon.com/images/I/61Pod1XCXkL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51hHGkcmarL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61gv14khtFL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61q0ENbWIuL._SX679_.jpg',
    gender: 'Male',
    price: 639 + 849,
    price1: 639,
    price2: 849,
    link1: 'https://amzn.to/4curZkt',
    link2: 'https://amzn.to/460ILoM',
  },
  {
    id: '29',
    pic: 'https://m.media-amazon.com/images/I/41S7buPHhbL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71LUXykfK9L._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51ZsAJKijZL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/41p6PMq3mEL._SY695_.jpg',
    gender: 'Female',
    price: 726 + 239,
    price1: 726,
    price2: 239,
    link1: 'https://amzn.to/4ci3GoU',
    link2: 'https://amzn.to/3AgC0nb',
  },
  {
    id: '30',
    pic: 'https://m.media-amazon.com/images/I/71Q0dEzqEGL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51-rUjSBhrL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71vHsSS35vL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/31NjQln6maL.jpg',
    gender: 'Male',
    price: 475 + 499,
    price1: 475,
    price2: 499,
    link1: 'https://amzn.to/4ckDVVO',
    link2: 'https://amzn.to/463W48h',
  },
]

export default glamorousItems
