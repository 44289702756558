// src/CategoryBox.js
import React from 'react';
import PropTypes from 'prop-types';
import './Categorybox.css';

const CategoryBox = ({ id, pic,pic1, price,categoryId }) => {
  return (
    <div className="category-box-c">
      <a
        href={`/category/${categoryId}/${id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="category-box-pics-c">
          <img src={pic} alt={`Item ${id}`} className="category-box-image-c" />
          <img src={pic1} alt={`Item ${id}`} className="category-box-image-c" />
        </div>
      </a>
      <div className="category-box-price-c">₹{price}</div>
    </div>
  )
};

CategoryBox.propTypes = {
  id: PropTypes.string.isRequired,
  pic: PropTypes.string.isRequired,
  pic1: PropTypes.string.isRequired,
  gender: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

export default CategoryBox;
