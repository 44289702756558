// src/Navbar.js
import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

const Navbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeDropdown)

    return () => {
      document.removeEventListener('mousedown', closeDropdown)
    }
  }, [])

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <a href="/">
          <img height={"76px"} width={"87px"} src="/images/logo.png" alt="Logo" />{' '}
        </a>
      </div>
      <div className="navbar-links">
        <Link to="/">Home</Link>
        <div className="navbar-dropdown" ref={dropdownRef}>
          <div className="navbar-category" onClick={toggleDropdown}>
            Categories &#8595;
            <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
              <Link to="/categories/a">Academia</Link>
              <Link to="/categories/b">Korean</Link>
              <Link to="/categories/c">Cottage Core</Link>
              <Link to="/categories/d">Street</Link>
              <Link to="/categories/e">Glamorous</Link>
            </div>
          </div>
        </div>
        <Link to="/about">About</Link>
      </div>
    </nav>
  )
}

export default Navbar
