const academiaItems = [
  {
    id: '1',
    pic: 'https://m.media-amazon.com/images/I/71--ffYu-yL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61mpRkpTXWL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61AdxXHpy7L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61LTpPr6fYL._SY741_.jpg',
    gender: 'Female',
    price: 989,
    price1: 499,
    price2: 490,
    link1: 'https://amzn.to/3XFkse6',
    link2: 'https://amzn.to/3xEIL17',
  },
  {
    id: '2',
    pic: 'https://m.media-amazon.com/images/I/51QhQjUl2wL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51Q5BgRMfFL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51R37UaXiFL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51hXkZKMk8L._SY741_.jpg',
    gender: 'Male',
    price: 799 + 299,
    price1: 799,
    price2: 299,
    link1: 'https://amzn.to/3RPlD6N',
    link2: 'https://amzn.to/3RNzMBq',
  },
  {
    id: '3',
    pic: 'https://m.media-amazon.com/images/I/41jdrgz67VL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51Z6znVOOKL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61JSbK6w56L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51C1WJ0PV+L._SY741_.jpg',
    gender: 'Female',
    price: 472 + 419,
    price1: 472,
    price2: 419,
    link1: 'https://amzn.to/45MaKsd',
    link2: 'https://amzn.to/45OZLhD',
  },
  {
    id: '4',
    pic: 'https://m.media-amazon.com/images/I/51E4l3RE5hL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71+pLF-dEKL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/41SjlxuagrL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71PsC9BiwnL._SY879_.jpg',
    gender: 'Male',
    price: 449 + 399,
    price1: 449,
    price2: 399,
    link1: 'https://amzn.to/4bpO6XZ',
    link2: 'https://amzn.to/3W76Vd1',
  },
  {
    id: '5',
    pic: 'https://m.media-amazon.com/images/I/71mjYfSWWKL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51EtiGhub3L._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71ilemUlLwL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51RNzSzOwDL._SY695_.jpg',
    gender: 'Female',
    price: 759 + 984,
    price1: 759,
    price2: 984,
    link1: 'https://amzn.to/3zkELmO',
    link2: 'https://amzn.to/3W31dKi',
  },
  {
    id: '6',
    pic: 'https://m.media-amazon.com/images/I/71PTFYRHO-L._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71xKHdMpJDL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61Ke+hAiBUL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71iz2p6RTFL._SY741_.jpg',
    gender: 'Male',
    price: 599 + 699,
    price1: 599,
    price2: 699,
    link1: 'https://amzn.to/3Ld3rAh',
    link2: 'https://amzn.to/4cMAcAp',
  },
  {
    id: '7',
    pic: 'https://m.media-amazon.com/images/I/914Qo7JIbPL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61m1GFXSPFL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71ow1J65yTL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51TyUq8pJnL._SY741_.jpg',
    gender: 'Female',
    price: 1089,
    price1: 570,
    price2: 519,
    link1: 'https://amzn.to/3W44fh9',
    link2: 'https://amzn.to/4clrLfy',
  },
  {
    id: '8',
    pic: 'https://m.media-amazon.com/images/I/61O2U-fi24L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81lsE2BPC6L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61tZvNBXrNL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71BSQxNEXML._SY741_.jpg',
    gender: 'Male',
    price: 499 + 799,
    price1: 499,
    price2: 799,
    link1: 'https://amzn.to/4cm8sT3',
    link2: 'https://amzn.to/4cMH9Bp',
  },
  {
    id: '9',
    pic: 'https://m.media-amazon.com/images/I/71PTIIg9ejL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71peWGcXsCL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71drTx0q7XL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71S2xGsSyiL._SY879_.jpg',
    gender: 'Female',
    price: 818,
    price1: 319,
    price2: 499,
    link1: 'https://amzn.to/3RQERcx',
    link2: 'https://amzn.to/45LySLw',
  },
  {
    id: '10',
    pic: 'https://m.media-amazon.com/images/I/61LRauEt6HL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61bxLd0ENlL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71iOpzK74DL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61Y+m0ij4nL._SX679_.jpg',
    gender: 'Male',
    price: 289 + 389,
    price1: 289,
    price2: 389,
    link1: 'https://amzn.to/45NmNpi',
    link2: 'https://amzn.to/3RRTU5z',
  },
  {
    id: '11',
    pic: 'https://m.media-amazon.com/images/I/71h9TK9onAL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41GY+TOfwRL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61MuBPxzdxL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51S7EPmDqvL._SY741_.jpg',
    gender: 'Female',
    price: 852,
    price1: 353,
    price2: 499,
    link1: 'https://amzn.to/45PdAww',
    link2: 'https://amzn.to/3xMRtu8',
  },
  {
    id: '12',
    pic: 'https://m.media-amazon.com/images/I/71P1phuyiRL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71yjtrneIFL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71JrfTJsiWL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/91VqNz35BcL._SX569_.jpg',
    gender: 'Male',
    price: 729 + 799,
    price1: 729,
    price2: 799,
    link1: 'https://amzn.to/45M9tS0',
    link2: 'https://amzn.to/3XNvgqI',
  },
  {
    id: '13',
    pic: 'https://m.media-amazon.com/images/I/51ICNnGYS9L._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61nXOvNRoDL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51ikjoC87uL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61HMxyj96jL._SY741_.jpg',
    gender: 'Female',
    price: 375 + 299,
    price1: 375,
    price2: 299,
    link1: 'https://amzn.to/3RSnTum',
    link2: 'https://amzn.to/3XNJJms',
  },
  {
    id: '14',
    pic: 'https://m.media-amazon.com/images/I/616wKnHNvSL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71A3spu5uVL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51DtTctEzWL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/91ZscLgIvoL._SX569_.jpg',
    gender: 'Male',
    price: 279 + 599,
    price1: 279,
    price2: 599,
    link1: 'https://amzn.to/3XJa453',
    link2: 'https://amzn.to/4eNV1Nl',
  },
  {
    id: '15',
    pic: 'https://m.media-amazon.com/images/I/71wLDWsQTAL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71JDWjxQbaL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81uks8N1TCL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71lgXYmpBtL._SY741_.jpg',
    gender: 'Female',
    price: 459 + 360,
    price1: 459,
    price2: 360,
    link1: 'https://amzn.to/3VO6Zhz',
    link2: 'https://amzn.to/3xT95ob',
  },
  {
    id: '16',
    pic: 'https://m.media-amazon.com/images/I/61Jp1mUdZeL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/619cPbgSKyL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61assrNSpBL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61P2u2Po0GL._SX569_.jpg',
    gender: 'Male',
    price: 457 + 599,
    price1: 457,
    price2: 599,
    link1: 'https://amzn.to/3XRVmJ1',
    link2: 'https://amzn.to/3xyzIyU',
  },
  {
    id: '17',
    pic: 'https://m.media-amazon.com/images/I/81yZ4vHNkhL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81s+wzoki1L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81bLCBuR9iL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/814RJJbvPmL._SY741_.jpg',
    gender: 'Female',
    price: 399 + 340,
    price1: 399,
    price2: 340,
    link1: 'https://amzn.to/3xJBjls',
    link2: 'https://amzn.to/4coGRki',
  },
  {
    id: '18',
    pic: 'https://m.media-amazon.com/images/I/61EYIj+k+HL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61PXP-DSkbL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61TKaQpzEFL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61JOJt7YpQL._SY741_.jpg',
    gender: 'Male',
    price: 499 + 299,
    price1: 499,
    price2: 299,
    link1: 'https://amzn.to/45Qq2fN',
    link2: 'https://amzn.to/4cPuxJS',
  },
  {
    id: '19',
    pic: 'https://m.media-amazon.com/images/I/51gLOPFxzBL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61ZtQHY56QL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51-NguH9tOL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61kxwO+RtmL._SY741_.jpg',
    gender: 'Female',
    price: 599 + 360,
    price1: 599,
    price2: 360,
    link1: 'https://amzn.to/3RTiF1q',
    link2: 'https://amzn.to/3RYyuDM',
  },
  {
    id: '20',
    pic: 'https://m.media-amazon.com/images/I/71FdqQOhxNL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71r3RWbLWAL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51btjvcNa-L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71Vt8Ti49rL._SY741_.jpg',
    gender: 'Male',
    price: 999 + 479,
    price1: 999,
    price2: 479,
    link1: 'https://amzn.to/3VShksO',
    link2: 'https://amzn.to/3VUfs2P',
  },
  {
    id: '21',
    pic: 'https://m.media-amazon.com/images/I/51BoV9TK5GL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51-5WtTFfWL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/31hk042J4zL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51QX6NVqmdL._SY741_.jpg',
    gender: 'Female',
    price: 349 + 255,
    price1: 349,
    price2: 255,
    link1: 'https://amzn.to/3LenLkT',
    link2: 'https://amzn.to/4cPVXPJ',
  },
  {
    id: '22',
    pic: 'https://m.media-amazon.com/images/I/51SuxU4vDnL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/511YLRQyR5L._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/519+uspCFRL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/5114icbwG2L._SX679_.jpg',
    gender: 'Male',
    price: 299 + 269,
    price1: 299,
    price2: 269,
    link1: 'https://amzn.to/3xQvFOw',
    link2: 'https://amzn.to/3WdqpO3',
  },
  {
    id: '23',
    pic: 'https://m.media-amazon.com/images/I/51a2cpEtxUL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61fnbLY+lWL._SX625_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71qS2hkYkgL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/612JwLQxKWL.jpg',
    gender: 'Female',
    price: 849 + 135,
    price1: 849,
    price2: 135,
    link1: 'https://amzn.to/45V48Ij',
    link2: 'https://amzn.to/3xPMkBE',
  },
  {
    id: '24',
    pic: 'https://m.media-amazon.com/images/I/611fQ9pLbgL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61t-dbDs-mL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61gx266fIAL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61xs2GCa7hL._SY741_.jpg',
    gender: 'Male',
    price: 498 + 299,
    price1: 498,
    price2: 299,
    link1: 'https://amzn.to/3WfQL24',
    link2: 'https://amzn.to/3xDSXXS',
  },
  {
    id: '25',
    pic: 'https://m.media-amazon.com/images/I/71TZAM-dsIL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51fXfL-mRjL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/719qdIoYwZL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51ApHMLO3qL._SY741_.jpg',
    gender: 'Female',
    price: 599 + 1199,
    price1: 599,
    price2: 1199,
    link1: 'https://amzn.to/4cY6HeL',
    link2: 'https://amzn.to/3W0CtAX',
  },
  {
    id: '26',
    pic: 'https://m.media-amazon.com/images/I/61CnrEmC60L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61CR0TisaRL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61HX30cMalL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61IrCzNnBoL._SY741_.jpg',
    gender: 'Male',
    price: 549 + 850,
    price1: 549,
    price2: 850,
    link1: 'https://amzn.to/3WlIjP0',
    link2: 'https://amzn.to/3xKVRtT',
  },
  {
    id: '27',
    pic: 'https://m.media-amazon.com/images/I/61IBoW3HhPL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/611j-cm70FL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/5115v6xQAtL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/518n03h12sL._SY879_.jpg',
    gender: 'Female',
    price: 428 + 399,
    price1: 428,
    price2: 399,
    link1: 'https://amzn.to/4bCKXE9',
    link2: 'https://amzn.to/3LjADGu',
  },
  {
    id: '28',
    pic: 'https://m.media-amazon.com/images/I/513pWOmKzZL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51jiSnpBo2L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61+A1CWaPsL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61pNVyhDCzL._SY741_.jpg',
    gender: 'Male',
    price: 577 + 429,
    price1: 577,
    price2: 429,
    link1: 'https://amzn.to/3zG4GWo',
    link2: 'https://amzn.to/3WkO0fW',
  },
  {
    id: '29',
    pic: 'https://m.media-amazon.com/images/I/31zNR80j8kL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51DoJVdSLWL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/31ZYmkeNVRL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51c1OQ+V-hL._SY741_.jpg',
    gender: 'Female',
    price: 399 + 690,
    price1: 399,
    price2: 690,
    link1: 'https://amzn.to/3A9Vz0f',
    link2: 'https://amzn.to/46G7WgN',
  },
  {
    id: '30',
    pic: 'https://m.media-amazon.com/images/I/61nuFQlAzKL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51pX6K5pt0L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51u1x1wG2tL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51wD6VVwV+L._SY741_.jpg',
    gender: 'Male',
    price: 350 + 799,
    price1: 350,
    price2: 799,
    link1: 'https://amzn.to/3SFwkJE',
    link2: 'https://amzn.to/4furOrc',
  },
]

export default academiaItems
