import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import './About.css'

const About = () => {
  return (
    <>
      <Navbar />
      <div className="about-page">
        <section className="about-us">
          <h2>About Us</h2>
          <p className="about-us-paragraph">
            Welcome to Outfitmate, where we believe that style should be a
            powerful tool for self-expression and confidence. Our diverse team
            of developers and designers is dedicated to making fashion
            accessible and enjoyable for all, regardless of size, shape, or
            style. We're committed to sustainability, inclusivity, and
            empowerment, and we're passionate about helping our community look
            and feel their best. ensuring that your shopping experience is
            effortless and enjoyable.
          </p>
          <p className="about-us-paragraph">
            At Outfitmate, we understand the challenges of browsing for clothes,
            and our innovative tool is here to simplify the process. No longer
            will looking good be reserved for those with personal fashion
            designers. Now, everyone can enjoy the confidence and elegance that
            comes with wearing well-coordinated outfits.
          </p>
          <p className="about-us-paragraph">
            We encourage you to embrace the habit of buying clothes in pairs,
            creating cohesive outfits rather than mixing and matching randomly.
            It's time to shed the old habit of wearing anything with anything.
          </p>
          <p className="about-us-paragraph">
            Let's all of us look great, with the OutfitMate !!!
          </p>
        </section>
        <hr />
        <section className="privacy-policy">
          <h2>Privacy Policy</h2>
          <p className="privacy-policy-paragraph">
            At Outfitmate, we prioritize your privacy and ensure that your
            personal information remains secure. We are committed to providing a
            safe and transparent browsing experience for our users.
          </p>
          <p className="privacy-policy-paragraph">
            Our website is designed purely for frontend viewing, and we do not
            store any user data in any form. You can browse our collections and
            explore our categories with complete peace of mind, knowing that
            your privacy is fully protected.
          </p>
          <p className="privacy-policy-paragraph">
            We do not collect, store, or share any personal information, and we
            are dedicated to maintaining the confidentiality of your browsing
            activity. Your trust is important to us, and we strive to uphold the
            highest standards of privacy and security.
          </p>
        </section>
        <hr />
        <section className="contact-us">
          <h2>Contact Us</h2>
          <p className="contact-us-paragraph">
            We are here to help you with any questions or concerns you may have.
            <br />
            Please feel free to reach out to us at any time.
          </p>
          <p className="contact-us-paragraph">
            For support, you can email us at{' '}
            <a href="mailto:outfitmate1@gmail.com">outfitmate1@gmail.com</a>
            <br />
            We strive to respond to all inquiries promptly and provide the
            assistance you need.
          </p>
          <p className="contact-us-paragraph">
            Stay connected with us on Instagram:{' '}
            <a
              href="https://www.instagram.com/outfit.mate"
              target="_blank"
              rel="noopener noreferrer"
            >
              @outfit.mate
            </a>
            <br />
            Follow us for the latest updates, fashion tips, and more.
          </p>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default About
