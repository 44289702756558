const streetItems = [
  {
    id: '1',
    pic: 'https://m.media-amazon.com/images/I/619YlEd+BCL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61TBtuZnDDL._SY879_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61lEIU1QfUL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61H3BRLc-yL._SY741_.jpg',
    gender: 'Male',
    price: 298 + 436,
    price1: 298,
    price2: 436,
    link1: 'https://amzn.to/3W4Idep',
    link2: 'https://amzn.to/3xHa3UI',
  },
  {
    id: '2',
    pic: 'https://m.media-amazon.com/images/I/61kYcwuPj-L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71QFX9ANGdL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61fIscYLDML._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/717UVpb6uUL._SY741_.jpg',
    gender: 'Female',
    price: 325 + 799,
    price1: 325,
    price2: 799,
    link1: 'https://amzn.to/3W2vV6f',
    link2: 'https://amzn.to/3W67nsQ',
  },
  {
    id: '3',
    pic: 'https://m.media-amazon.com/images/I/51jCViGieQL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41YqOhW8LtL.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51h8ljM0k0L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/41OfUbOyXvL.jpg',
    gender: 'Male',
    price: 298 + 869,
    price1: 298,
    price2: 869,
    link1: 'https://amzn.to/45M3soo',
    link2: 'https://amzn.to/3SdJwWb',
  },
  {
    id: '4',
    pic: 'https://m.media-amazon.com/images/I/81+1GVhfPbL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81swej4P9qL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/712SaIJ97rL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/81VWGR9WJKL._SY741_.jpg',
    gender: 'Female',
    price: 349 + 799,
    price1: 349,
    price2: 799,
    link1: 'https://amzn.to/4eO2rR4',
    link2: 'https://amzn.to/4bH8LqN',
  },
  {
    id: '5',
    pic: 'https://m.media-amazon.com/images/I/51ppnDMnT-L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51jh3m-6iBL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61yptvxwVBL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61t0jUhGYhL._SX679_.jpg',
    gender: 'Male',
    price: 549 + 1499,
    price1: 549,
    price2: 1499,
    link1: 'https://amzn.to/4bteL6i',
    link2: 'https://amzn.to/3zyamBy',
  },
  {
    id: '6',
    pic: 'https://m.media-amazon.com/images/I/7191ui-uF8L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81mzPJi9o3L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71ld2IW2K4L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/814STODbC6L._SY741_.jpg',
    gender: 'Female',
    price: 599 + 950,
    price1: 599,
    price2: 950,
    link1: 'https://amzn.to/3W44VmH',
    link2: 'https://amzn.to/3W8j4Q8',
  },
  {
    id: '7',
    pic: 'https://m.media-amazon.com/images/I/51U5M0wPMdS._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61oZhfBrvXL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51u5kZiQCyS._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/719gVa3AEFL._SY741_.jpg',
    gender: 'Female',
    price: 367 + 399,
    price1: 367,
    price2: 399,
    link1: 'https://amzn.to/3RQo2hJ',
    link2: 'https://amzn.to/3Ld4gcl',
  },
  {
    id: '8',
    pic: 'https://m.media-amazon.com/images/I/61OMRFcYGxL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71XE+nb4oRL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61XinivvkVL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71oOaQY3CTL._SY741_.jpg',
    gender: 'Male',
    price: 379 + 699,
    price1: 379,
    price2: 699,
    link1: 'https://amzn.to/4bvD3ML',
    link2: 'https://amzn.to/3VUnGba',
  },
  {
    id: '9',
    pic: 'https://m.media-amazon.com/images/I/514WgOgPqaL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61WhwYJWLsS._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51RehV3EtsL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71W8kR80yHS._SY695_.jpg',
    gender: 'Female',
    price: 999 + 663,
    price1: 999,
    price2: 663,
    link1: 'https://amzn.to/4cJ4hR0',
    link2: 'https://amzn.to/3XKWfDe',
  },
  {
    id: '10',
    pic: 'https://m.media-amazon.com/images/I/516nkayGWWL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/311G1a0vV7L.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61rcS0Y57dL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/41cLpIHCQBL.jpg',
    gender: 'Male',
    price: 649 + 799,
    price1: 649,
    price2: 799,
    link1: 'https://amzn.to/4cJMo4Q',
    link2: 'https://amzn.to/3XNoS2I',
  },
  {
    id: '11',
    pic: 'https://m.media-amazon.com/images/I/51aUyJMlbOL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61buv8y8SNL._SX695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61Y6p1dKWrL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/714BUK17GyL._SY695_.jpg',
    gender: 'Female',
    price: 549 + 466,
    price1: 549,
    price2: 466,
    link1: 'https://amzn.to/3XNpVzG',
    link2: 'https://amzn.to/45Kl9Vx',
  },
  {
    id: '12',
    pic: 'https://m.media-amazon.com/images/I/71+7SnCOuNL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61IIKfT7AZL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61aCI4N6AXL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61VVxZ0o28L._SX679_.jpg',
    gender: 'Male',
    price: 298 + 479,
    price1: 298,
    price2: 479,
    link1: 'https://amzn.to/3znbo3x',
    link2: 'https://amzn.to/4czrfu2',
  },
  {
    id: '13',
    pic: 'https://m.media-amazon.com/images/I/71pVu5xZ08L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51pcACkJN7L._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71yfmkrYWCL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/714VGkPGY+L._SY695_.jpg',
    gender: 'Female',
    price: 499 + 599,
    price1: 499,
    price2: 599,
    link1: 'https://amzn.to/3W6IuNH',
    link2: 'https://amzn.to/4cpRPWR',
  },
  {
    id: '14',
    pic: 'https://m.media-amazon.com/images/I/41WbX90ZzVL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61TjkaZTUTL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51alIWxmbbL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71QRJ39UasL._SY741_.jpg',
    gender: 'Male',
    price: 274 + 479,
    price1: 274,
    price2: 479,
    link1: 'https://amzn.to/3xxUHln',
    link2: 'https://amzn.to/4bt5wTu',
  },
  {
    id: '15',
    pic: 'https://m.media-amazon.com/images/I/71FbWOCGlHL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71CwKx4S-HL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61P4gTVAKmL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/611EXc7iFnL._SY695_.jpg',
    gender: 'Female',
    price: 369 + 886,
    price1: 369,
    price2: 886,
    link1: 'https://amzn.to/4eQmhuM',
    link2: 'https://amzn.to/4eTj5hS',
  },
  {
    id: '16',
    pic: 'https://m.media-amazon.com/images/I/41ZhCf82xcL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71rEzJBgwHL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/41ZhCf82xcL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/7166+Wcj6AL._SY741_.jpg',
    gender: 'Male',
    price: 599 + 379,
    price1: 599,
    price2: 379,
    link1: 'https://amzn.to/4eNkFBQ',
    link2: 'https://amzn.to/3RMjJ72',
  },
  {
    id: '17',
    pic: 'https://m.media-amazon.com/images/I/71ytxGax2WL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71sOuP7T-QL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51qA+FtX+GL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71cTloxVBsL._SY741_.jpg',
    gender: 'Female',
    price: 355 + 499,
    price1: 355,
    price2: 499,
    link1: 'https://amzn.to/3VUnVD6',
    link2: 'https://amzn.to/3RRgjjC',
  },
  {
    id: '18',
    pic: 'https://m.media-amazon.com/images/I/511kgCPI5eL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71VcLuyQcqL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51dt0ZbEAuL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71mKL9zUH1L._SX569_.jpg',
    gender: 'Male',
    price: 329 + 599,
    price1: 329,
    price2: 599,
    link1: 'https://amzn.to/3zwlcIx',
    link2: 'https://amzn.to/4cOBLgS',
  },
  {
    id: '19',
    pic: 'https://m.media-amazon.com/images/I/817HtEA8H+L._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/610xTgKXoxL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/91yTOXgGCbL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61EKjaUkZHL._SY741_.jpg',
    gender: 'Female',
    price: 349 + 699,
    price1: 349,
    price2: 699,
    link1: 'https://amzn.to/3zFoA3A',
    link2: 'https://amzn.to/45WDjDr',
  },
  {
    id: '20',
    pic: 'https://m.media-amazon.com/images/I/71HT+ZLQCkL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61C45VQjoXL._SY879_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61u+G6D20iL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61Y8T1B183L._SY741_.jpg',
    gender: 'Male',
    price: 299 + 489,
    price1: 299,
    price2: 489,
    link1: 'https://amzn.to/3XSwj8v',
    link2: 'https://amzn.to/3RWFaT2',
  },
  {
    id: '21',
    pic: 'https://m.media-amazon.com/images/I/71UqvzU2VpL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/21fpTiDdeUL.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71qna6DlgCL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/316fehWwcVL.jpg',
    gender: 'Female',
    price: 377 + 330,
    price1: 377,
    price2: 330,
    link1: 'https://amzn.to/4bIxv1S',
    link2: 'https://amzn.to/4bBZoIA',
  },
  {
    id: '22',
    pic: 'https://m.media-amazon.com/images/I/51HNbkRR0nL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71hYuO6u+WL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/610MXtVJ8XL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71xF2BcNKGL._SY741_.jpg',
    gender: 'Male',
    price: 371 + 749,
    price1: 371,
    price2: 749,
    link1: 'https://amzn.to/3LfimtT',
    link2: 'https://amzn.to/4cyIj3D',
  },
  {
    id: '23',
    pic: 'https://m.media-amazon.com/images/I/717s83gHTSL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71A+PMo02BL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81YKYvAUDAL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71ErB6kOYYL._SX569_.jpg',
    gender: 'Female',
    price: 349 + 304,
    price1: 349,
    price2: 304,
    link1: 'https://amzn.to/3RV3QLt',
    link2: 'https://amzn.to/3xH3iCm',
  },
  {
    id: '24',
    pic: 'https://m.media-amazon.com/images/I/514LDJSjOIL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71PEvCzT0+L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/6185yo5S-7L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71VhcKaLPmL._SY741_.jpg',
    gender: 'Male',
    price: 499 + 449,
    price1: 499,
    price2: 449,
    link1: 'https://amzn.to/4ctojiS',
    link2: 'https://amzn.to/3xXI8Qg',
  },
  {
    id: '25',
    pic: 'https://m.media-amazon.com/images/I/81hGN2AA-QL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41uZ8xkQTvL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/812biA5GKGL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61Io9trL6OL._SX569_.jpg',
    gender: 'Female',
    price: 359 + 249,
    price1: 359,
    price2: 249,
    link1: 'https://amzn.to/3Y15teB',
    link2: 'https://amzn.to/3Y0ezrS',
  },
  {
    id: '26',
    pic: 'https://m.media-amazon.com/images/I/71H9rfud8AL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41PB4sZtqrL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61MIsLaM+EL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/31aanPQYg7L._SY741_.jpg',
    gender: 'Male',
    price: 298 + 329,
    price1: 298,
    price2: 329,
    link1: 'https://amzn.to/3zy1xaX',
    link2: 'https://amzn.to/4bGpg6b',
  },
  {
    id: '27',
    pic: 'https://m.media-amazon.com/images/I/81CL0dVkXML._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81b7Xavv5AL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71pzZ9Klj7L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/81Q1FGWXluL._SX679_.jpg',
    gender: 'Female',
    price: 299 + 699,
    price1: 299,
    price2: 699,
    link1: 'https://amzn.to/46533xn',
    link2: 'https://amzn.to/3XXa6GF',
  },
  {
    id: '28',
    pic: 'https://m.media-amazon.com/images/I/61qdRv4TUNL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51wm84K+iLL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61imrdcjnpL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51H7NppxAYL._SY741_.jpg',
    gender: 'Male',
    price: 498 + 699,
    price1: 498,
    price2: 699,
    link1: 'https://amzn.to/4d0dBjE',
    link2: 'https://amzn.to/4cDxGN8',
  },
  {
    id: '29',
    pic: 'https://m.media-amazon.com/images/I/81tkacyTt8L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51Ci6+XM37L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81cmmWIX7wL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51O+9xGtEaL._SY741_.jpg',
    gender: 'Female',
    price: 219 + 299,
    price1: 219,
    price2: 299,
    link1: 'https://amzn.to/3ytZl4g',
    link2: 'https://amzn.to/4cholJQ',
  },
  {
    id: '30',
    pic: 'https://m.media-amazon.com/images/I/81v7FH9dgfL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/516DLETnyCL._SY879_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81JnjCPzrjL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61050JEH3eL._SY741_.jpg',
    gender: 'Male',
    price: 349 + 945,
    price1: 349,
    price2: 945,
    link1: 'https://amzn.to/4dcbdXR',
    link2: 'https://amzn.to/3ywefXE',
  },
]

export default streetItems
