import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom'
import './Homepage.css'

const Homepage = () => {
    return (
      <>
        <Navbar />
        <div className="home">
          <span>Pick Your Vibe</span>
          <div className="home-categories">
            <Link
              to="/categories/a"
              className="category-box"
              style={{ backgroundImage: `url('/images/academia.webp')` }}
            >
              <div className="category-name">Academia</div>
            </Link>
            <Link
              to="/categories/b"
              className="category-box"
              style={{ backgroundImage: `url('/images/korean.webp')` }}
            >
              <div className="category-name">Korean</div>
            </Link>
            <Link
              to="/categories/c"
              className="category-box"
              style={{ backgroundImage: `url('/images/cottagecore.webp')` }}
            >
              <div className="category-name">Cottage Core</div>
            </Link>
            <Link
              to="/categories/d"
              className="category-box"
              style={{ backgroundImage: `url('/images/street.webp')` }}
            >
              <div className="category-name">Street</div>
            </Link>
            <Link
              to="/categories/e"
              className="category-box"
              style={{ backgroundImage: `url('/images/glamorous.webp')` }}
            >
              <div className="category-name">Glamorous</div>
            </Link>
          </div>
        </div>
        <Footer />
      </>
    )
}
export default Homepage