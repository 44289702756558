const koreanItems = [
  {
    id: '1',
    pic: 'https://m.media-amazon.com/images/I/61PcXi5UWAL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61W3-DqG+2L._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71M-fBsUgoL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51-XA3hOSvL._SX679_.jpg',
    gender: 'Female',
    price: 489 + 399,
    price1: 489,
    price2: 399,
    link1: 'https://amzn.to/4bwJMWJ',
    link2: 'https://amzn.to/3W44rgn',
  },
  {
    id: '2',
    pic: 'https://m.media-amazon.com/images/I/61usHOelMiL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61wFZslCZML._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61LNW9XmLxL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61wFZslCZML._SY741_.jpg',
    gender: 'Male',
    price: 449 + 479,
    price1: 449,
    price2: 479,
    link1: 'https://amzn.to/3L6w0zC',
    link2: 'https://amzn.to/3L38pzM',
  },
  {
    id: '3',
    pic: 'https://m.media-amazon.com/images/I/81-11O3CO1L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51+6HX5NKnL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71q14HfYioL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51iUei5DdrL._SY741_.jpg',
    gender: 'Female',
    price: 1753,
    price1: 494,
    price2: 1259,
    link1: 'https://amzn.to/3zsEzlF',
    link2: 'https://amzn.to/3XJjvRW',
  },
  {
    id: '4',
    pic: 'https://m.media-amazon.com/images/I/71U0K+fTUtL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71ya8TFZJHL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71W+kOgr4aL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71vvaiuF2hL._SY741_.jpg',
    gender: 'Male',
    price: 329 + 699,
    price1: 329,
    price2: 699,
    link1: 'https://amzn.to/4cLv0MP',
    link2: 'https://amzn.to/4eIeoHP',
  },
  {
    id: '5',
    pic: 'https://m.media-amazon.com/images/I/51Pyb13ouZL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/513QeKqhy4L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61pMOYoCXfL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51ijrJCn--L._SY741_.jpg',
    gender: 'Female',
    price: 699 + 360,
    price1: 699,
    price2: 360,
    link1: 'https://amzn.to/3W3LGts',
    link2: 'https://amzn.to/3RRC7LX',
  },
  {
    id: '6',
    pic: 'https://m.media-amazon.com/images/I/71qFgTzGboL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51rjO89bf1L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71EqoV0fyGL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51L2ERkNZAL._SY741_.jpg',
    gender: 'Male',
    price: 449 + 699,
    price1: 449,
    price2: 699,
    link1: 'https://amzn.to/3VOVBC4',
    link2: 'https://amzn.to/4bqY0Za',
  },
  {
    id: '7',
    pic: 'https://m.media-amazon.com/images/I/31cNYhaHOSL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71HD2rJxDHL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61W+etciVYL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/711KPTEZvlL._SX569_.jpg',
    gender: 'Male',
    price: 349 + 799,
    price1: 349,
    price2: 799,
    link1: 'https://amzn.to/45LFY2S',
    link2: 'https://amzn.to/4ciD2x0',
  },
  {
    id: '8',
    pic: 'https://m.media-amazon.com/images/I/51OcYzqNGRL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51eaHjAB0jL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51bu1P4lHZL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51Clvs6gtYL._SY741_.jpg',
    gender: 'Female',
    price: 293 + 329,
    price1: 329,
    price2: 293,
    link1: 'https://amzn.to/4cr7Pb9',
    link2: 'https://amzn.to/4eLCfq2',
  },
  {
    id: '9',
    pic: 'https://m.media-amazon.com/images/I/61uCdKfkzzL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61FU5-5KPbL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/619jBjXmOFL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61cHYeaqqxL._SY741_.jpg',
    gender: 'Male',
    price: 449 + 649,
    price1: 449,
    price2: 649,
    link1: 'https://amzn.to/3xyB3Ws',
    link2: 'https://amzn.to/4bMFXND',
  },
  {
    id: '10',
    pic: 'https://m.media-amazon.com/images/I/61IPWTnQseL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61Aek7MQ7sL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61CZnhpYXnL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61VdSsczkcL._SX679_.jpg',
    gender: 'Female',
    price: 894,
    price1: 299,
    price2: 595,
    link1: 'https://amzn.to/4cnyJAE',
    link2: 'https://amzn.to/3RPBQt0',
  },
  {
    id: '11',
    pic: 'https://m.media-amazon.com/images/I/71AdD3SQSIL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61mr6N2TVBL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71JTOCPQuSL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61qmSJswyjL._SY741_.jpg',
    gender: 'Male',
    price: 349 + 699,
    price1: 349,
    price2: 699,
    link1: 'https://amzn.to/3VKXijO',
    link2: 'https://amzn.to/3VJOquR',
  },
  {
    id: '12',
    pic: 'https://m.media-amazon.com/images/I/615dppPrD4L._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/6191bH6aJJL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61JvywPRSrL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/6137KqVR+vL._SX569_.jpg',
    gender: 'Female',
    price: 943,
    price1: 650,
    price2: 293,
    link1: 'https://amzn.to/4blBFMQ',
    link2: 'https://amzn.to/4eMMLNW',
  },
  {
    id: '13',
    pic: 'https://m.media-amazon.com/images/I/41X7cLISTLL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61DWilcxm4L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/412lDPKSshL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61uaLkBL-HL._SY741_.jpg',
    gender: 'Male',
    price: 399 + 499,
    price1: 399,
    price2: 499,
    link1: 'https://amzn.to/3RNdyiX',
    link2: 'https://amzn.to/3RTMSx4',
  },
  {
    id: '14',
    pic: 'https://m.media-amazon.com/images/I/71kpwYPrY9L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/81YMOj8HjuL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71ToQonjqUL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/41GFy2fe-+L.jpg',
    gender: 'Female',
    price: 808,
    price1: 359,
    price2: 449,
    link1: 'https://amzn.to/45RlLbN',
    link2: 'https://amzn.to/3VO5Oyp',
  },
  {
    id: '15',
    pic: 'https://m.media-amazon.com/images/I/711g3eoistL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61twY1BnmfL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61E+0O4EaiL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61naGp-R7fL._SY741_.jpg',
    gender: 'Male',
    price: 429 + 649,
    price1: 429,
    price2: 649,
    link1: 'https://amzn.to/4eOcQfn',
    link2: 'https://amzn.to/45Qtd6Y',
  },
  {
    id: '16',
    pic: 'https://m.media-amazon.com/images/I/71zjXhpONHL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61oj2SOHnGL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71NR4MwqkjL._SY879_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61-rMO9QlaL._SX569_.jpg',
    gender: 'Female',
    price: 359 + 349,
    price1: 359,
    price2: 349,
    link1: 'https://amzn.to/3xVM0RP',
    link2: 'https://amzn.to/3VQmeGE',
  },
  {
    id: '17',
    pic: 'https://m.media-amazon.com/images/I/81kSZI9qN-L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/515jdmkSMDL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81Z60TWdX4L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51T0s+tkt7L._SY741_.jpg',
    gender: 'Male',
    price: 429 + 699,
    price1: 429,
    price2: 699,
    link1: 'https://amzn.to/45M3gpa',
    link2: 'https://amzn.to/3RPlYX7',
  },
  {
    id: '18',
    pic: 'https://m.media-amazon.com/images/I/51b9wLyYovL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51UUaGkGO8L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51sx3K1iekL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51IBAUlOyJL._SY741_.jpg',
    gender: 'Female',
    price: 399 + 495,
    price1: 399,
    price2: 495,
    link1: 'https://amzn.to/3LwQOR7',
    link2: 'https://amzn.to/3Ldeeuw',
  },
  {
    id: '19',
    pic: 'https://m.media-amazon.com/images/I/51DAQZmlp1L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/615t5EnhdUS._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51rKMQ-nZ8L._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61MSTpxnkeS._SY741_.jpg',
    gender: 'Male',
    price: 349 + 456,
    price1: 349,
    price2: 456,
    link1: 'https://amzn.to/3zFGWS4',
    link2: 'https://amzn.to/3RT3Vzx',
  },
  {
    id: '20',
    pic: 'https://m.media-amazon.com/images/I/61xFyIMW8FL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61hLcMtFsFL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61yfZiZJ7kL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51Woy7knzzL._SY741_.jpg',
    gender: 'Female',
    price: 299 + 289,
    price1: 299,
    price2: 289,
    link1: 'https://amzn.to/3W9Dubc',
    link2: 'https://amzn.to/3XUj4oa',
  },
  {
    id: '21',
    pic: 'https://m.media-amazon.com/images/I/613rYIKkqyL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/710sutINcYL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61nMTYo6KSL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/710Wq4cILqL._SY741_.jpg',
    gender: 'Male',
    price: 449 + 449,
    price1: 449,
    price2: 449,
    link1: 'https://amzn.to/4f5u08y',
    link2: 'https://amzn.to/3RZOo0C',
  },
  {
    id: '22',
    pic: 'https://m.media-amazon.com/images/I/512mRF5S4mL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71kegLGIfjS._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51IqsD79qXL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71SZhuStOzS._SY741_.jpg',
    gender: 'Female',
    price: 398 + 699,
    price1: 398,
    price2: 699,
    link1: 'https://amzn.to/3XUL3nv',
    link2: 'https://amzn.to/4eS0SkV',
  },
  {
    id: '23',
    pic: 'https://m.media-amazon.com/images/I/61EzNyKRgJL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/713edboqFVL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51OkRfWe0hL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61W8mTOvgNL._SY741_.jpg',
    gender: 'Male',
    price: 429 + 479,
    price1: 429,
    price2: 479,
    link1: 'https://amzn.to/4cRs4hZ',
    link2: 'https://amzn.to/3xSKfox',
  },
  {
    id: '24',
    pic: 'https://m.media-amazon.com/images/I/71jiQmQ8-HL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/611yAQrsWBL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71b9EwBRnyL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61jfBIZmcmL._SY741_.jpg',
    gender: 'Female',
    price: 439 + 329,
    price1: 439,
    price2: 329,
    link1: 'https://amzn.to/45Wv76i',
    link2: 'https://amzn.to/3xPWFNW',
  },
  {
    id: '25',
    pic: 'https://m.media-amazon.com/images/I/618AldcT9TL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61m963ro25L._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/610Ylp9CxvL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61IMu7Hvm-L._SY741_.jpg',
    gender: 'Male',
    price: 399 + 832,
    price1: 399,
    price2: 832,
    link1: 'https://amzn.to/3xLjbaX',
    link2: 'https://amzn.to/3Lo39He',
  },
  {
    id: '26',
    pic: 'https://m.media-amazon.com/images/I/61v9bmaM9tL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51KVoDBpONL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61MzbgAWwiL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/517XP9BpxEL._SX569_.jpg',
    gender: 'Female',
    price: 289 + 399,
    price1: 289,
    price2: 399,
    link1: 'https://amzn.to/3S69MBB',
    link2: 'https://amzn.to/3Stw9kP',
  },
  {
    id: '27',
    pic: 'https://m.media-amazon.com/images/I/61O4Mb6Qd9L._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61nzp0kboDL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51JwU6aJBcL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/613-E1g-kmL._SX569_.jpg',
    gender: 'Male',
    price: 699 + 479,
    price1: 699,
    price2: 479,
    link1: 'https://amzn.to/3Lj5HX3',
    link2: 'https://amzn.to/461Qrao',
  },
  {
    id: '28',
    pic: 'https://m.media-amazon.com/images/I/51u3BVJZYDL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61ZCrm49euL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51HmMmRRHPL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61Qv6q9GJoL._SY741_.jpg',
    gender: 'Female',
    price: 351 + 299,
    price1: 351,
    price2: 299,
    link1: 'https://amzn.to/3Y0J4hC',
    link2: 'https://amzn.to/3WkB3Tk',
  },
  {
    id: '29',
    pic: 'https://m.media-amazon.com/images/I/51fQWJl3LSL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61d+1ed-q9L._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/414HeoH28cL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61ChwP6pbjL._SY741_.jpg',
    gender: 'Male',
    price: 399 + 449,
    price1: 399,
    price2: 449,
    link1: 'https://amzn.to/4ckWt7I',
    link2: 'https://amzn.to/4d6fADD',
  },
  {
    id: '30',
    pic: 'https://m.media-amazon.com/images/I/61HvevYUy0L._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41XZPj6tXcL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61oc25CyXUL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/514BX0olXHL._SX679_.jpg',
    gender: 'Female',
    price: 999 + 140,
    price1: 999,
    price2: 140,
    link1: 'https://amzn.to/3AdpF3c',
    link2: 'https://amzn.to/46CIGYG',
  },
]

export default koreanItems
