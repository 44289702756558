const cottageItems = [
  {
    id: '1',
    pic: 'https://m.media-amazon.com/images/I/61HfipQ+zrL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61HdOwBffLL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61dEXmUEbWL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61YNnLtkN8L._SY741_.jpg',
    gender: 'Male',
    price: 271 + 659,
    price1: 271,
    price2: 659,
    link1: 'https://amzn.to/3W1RtQi',
    link2: 'https://amzn.to/3W48dq4',
  },
  {
    id: '2',
    pic: 'https://m.media-amazon.com/images/I/81hPi3oWpsL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/517iCPPvcgL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81sd5gxSY-L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61SzEGyLO-L._SY741_.jpg',
    gender: 'Female',
    price: 449 + 275,
    price1: 449,
    price2: 275,
    link1: 'https://amzn.to/3VVAqy6',
    link2: 'https://amzn.to/4bFfpxK',
  },
  {
    id: '3',
    pic: 'https://m.media-amazon.com/images/I/71q4Hr4RuwL._SY879_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71mPQJaw85L._SY879_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61PzyUweDSL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71w9wGNPpjL._SX569_.jpg',
    gender: 'Male',
    price: 349 + 699,
    price1: 349,
    price2: 699,
    link1: 'https://amzn.to/3XU5Rf3',
    link2: 'https://amzn.to/3L5icoU',
  },
  {
    id: '4',
    pic: 'https://m.media-amazon.com/images/I/71yXb7aHaOL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41TRbTSlMOL.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71jdjYSHijL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71T9gKStdZL._SY625_.jpg',
    gender: 'Female',
    price: 439 + 169,
    price1: 439,
    price2: 169,
    link1: 'https://amzn.to/4feQLXN',
    link2: 'https://amzn.to/4f1RfQH',
  },
  {
    id: '5',
    pic: 'https://m.media-amazon.com/images/I/61uzXYrl+IL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61TKitTYCGL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51snGA0dpPL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61WyecXpHIL._SY741_.jpg',
    gender: 'Male',
    price: 299 + 729,
    price1: 299,
    price2: 729,
    link1: 'https://amzn.to/3XPcIGn',
    link2: 'https://amzn.to/3VPn9qK',
  },
  {
    id: '6',
    pic: 'https://m.media-amazon.com/images/I/619wkhhui9L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/8160LK8SfOL._SY879_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71t3Xd7WglL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71Rnqz-nbtL._SY879_.jpg',
    gender: 'Female',
    price: 989 + 1139,
    price1: 989,
    price2: 1139,
    link1: 'https://amzn.to/3zxSo22',
    link2: 'https://amzn.to/4bqju8E',
  },
  {
    id: '7',
    pic: 'https://m.media-amazon.com/images/I/51unFTvn20L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51uX9k9I0tL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51k2dNna82L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61WQsqpokOL._SY741_.jpg',
    gender: 'Male',
    price: 899 + 665,
    price1: 899,
    price2: 665,
    link1: 'https://amzn.to/3xMS6nu',
    link2: 'https://amzn.to/4bqjvte',
  },
  {
    id: '8',
    pic: 'https://m.media-amazon.com/images/I/71t4rQnRkkL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71ERVxmy5HL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81ypu4J5fBL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71lUXuPzJsL._SY741_.jpg',
    gender: 'Female',
    price: 730,
    price1: 399,
    price2: 331,
    link1: 'https://amzn.to/3VOM7qg',
    link2: 'https://amzn.to/3zASfec',
  },
  {
    id: '9',
    pic: 'https://m.media-amazon.com/images/I/71zvHRU0CxL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/7142oodkIvL._SY879_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71pzLQ9cdRL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/91maTWxZKcL._SX569_.jpg',
    gender: 'Male',
    price: 475 + 699,
    price1: 475,
    price2: 699,
    link1: 'https://amzn.to/4bpZCCO',
    link2: 'https://amzn.to/4eMcaqW',
  },
  {
    id: '10',
    pic: 'https://m.media-amazon.com/images/I/61cTpUICV5L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/91VVJgl85JL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61ZoGDKTVnL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/914rBzvgcgL._SY741_.jpg',
    gender: 'Female',
    price: 538,
    price1: 259,
    price2: 279,
    link1: 'https://amzn.to/3L7YqsO',
    link2: 'https://amzn.to/3LbDBN2',
  },
  {
    id: '11',
    pic: 'https://m.media-amazon.com/images/I/518dhXvFmtL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71Wdg07hMtL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51JLoMPF8uL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/912VSxkb8wL._SX569_.jpg',
    gender: 'Male',
    price: 299 + 599,
    price1: 299,
    price2: 599,
    link1: 'https://amzn.to/3XGnlvb',
    link2: 'https://amzn.to/3XNpzJm',
  },
  {
    id: '12',
    pic: 'https://m.media-amazon.com/images/I/61huo3lW7HL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/5185jEBIeVL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61-Nhn3bVFL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51Juv8ZDIhL._SY695_.jpg',
    gender: 'Female',
    price: 599 + 395,
    price1: 599,
    price2: 395,
    link1: 'https://amzn.to/3XMX26G',
    link2: 'https://amzn.to/45UhAfy',
  },
  {
    id: '13',
    pic: 'https://m.media-amazon.com/images/I/61j50gSxTRL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51KUtbVuzdL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/610RKyioa+L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61FItU8IrKL._SY741_.jpg',
    gender: 'Male',
    price: 359 + 729,
    price1: 359,
    price2: 729,
    link1: 'https://amzn.to/4cpQ4c0',
    link2: 'https://amzn.to/3XL39bF',
  },
  {
    id: '14',
    pic: 'https://m.media-amazon.com/images/I/51TC-fYTTPL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51K1RPQTjJL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51E2P2MdEFL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/416Bud-g--L._SY695_.jpg',
    gender: 'Female',
    price: 711 + 227,
    price1: 711,
    price2: 227,
    link1: 'https://amzn.to/4cpUhwx',
    link2: 'https://amzn.to/4coW0C1',
  },
  {
    id: '15',
    pic: 'https://m.media-amazon.com/images/I/71HV2WtOybL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61YD07YFWJL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81-CAtd1P6L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61tpRIpPoYL._SX569_.jpg',
    gender: 'Male',
    price: 299 + 859,
    price1: 299,
    price2: 859,
    link1: 'https://amzn.to/4eTspme',
    link2: 'https://amzn.to/3W7u93M',
  },
  {
    id: '16',
    pic: 'https://m.media-amazon.com/images/I/71Kk9FNP4lL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41m9lnaFvpL.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71Q4MM9JFcL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/31puAYSEQQL.jpg',
    gender: 'Female',
    price: 389 + 259,
    price1: 389,
    price2: 259,
    link1: 'https://amzn.to/3zwj1Vn',
    link2: 'https://amzn.to/3RVWnMa',
  },
  {
    id: '17',
    pic: 'https://m.media-amazon.com/images/I/81mbkeQmusL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41lcP1+EFrL._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71wK62dlXXL._SX569_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/411ssWygUIL._SX679_.jpg',
    gender: 'Male',
    price: 319 + 499,
    price1: 319,
    price2: 499,
    link1: 'https://amzn.to/3VKJgyw',
    link2: 'https://amzn.to/3XPpnco',
  },
  {
    id: '18',
    pic: 'https://m.media-amazon.com/images/I/81FAx6-lNLL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51L+WGYploL.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81Npz+OmxML._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/91Yf5SD-zqL._SY741_.jpg',
    gender: 'Female',
    price: 824 + 299,
    price1: 824,
    price2: 299,
    link1: 'https://amzn.to/3xH9Uk8',
    link2: 'https://amzn.to/4cm8MBf',
  },
  {
    id: '19',
    pic: 'https://m.media-amazon.com/images/I/81oMFp+78VL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/519fNDQzNJL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51NZrdwG83L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51cXd0YkU8L._SX569_.jpg',
    gender: 'Male',
    price: 299 + 507,
    price1: 299,
    price2: 507,
    link1: 'https://amzn.to/3VUeVxR',
    link2: 'https://amzn.to/3LfPzpi',
  },
  {
    id: '20',
    pic: 'https://m.media-amazon.com/images/I/91AE+1cOlGL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61kytAS2WHL._SY625_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/91oRWE25u-L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61EKswvNr3L._SY625_.jpg',
    gender: 'Female',
    price: 689 + 139,
    price1: 689,
    price2: 139,
    link1: 'https://amzn.to/4eViEUg',
    link2: 'https://amzn.to/45TefNM',
  },
  {
    id: '21',
    pic: 'https://m.media-amazon.com/images/I/71Gmi8W2vxL._SX569_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71yjtrneIFL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/81Csrz4WW5L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71+4Ck264hL._SX569_.jpg',
    gender: 'Male',
    price: 299 + 699,
    price1: 299,
    price2: 699,
    link1: 'https://amzn.to/3xWgO4U',
    link2: 'https://amzn.to/3RYGF3a',
  },
  {
    id: '22',
    pic: 'https://m.media-amazon.com/images/I/61mO2NnNs6L._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/31EB8PeI7VL.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61yjOdebkvL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/21FR-WTCtVL.jpg',
    gender: 'Female',
    price: 589 + 339,
    price1: 589,
    price2: 339,
    link1: 'https://amzn.to/3LhCUCg',
    link2: 'https://amzn.to/463Lhuz',
  },
  {
    id: '23',
    pic: 'https://m.media-amazon.com/images/I/618sOJJHUlL.jpg',
    pic1: 'https://m.media-amazon.com/images/I/811jRkUIPGL._SX569_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61y0Ouwn0ZL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/91XTuKPS9KL._SX569_.jpg',
    gender: 'Male',
    price: 299 + 599,
    price1: 299,
    price2: 599,
    link1: 'https://amzn.to/3WeVMrx',
    link2: 'https://amzn.to/4cyrJ42',
  },
  {
    id: '24',
    pic: 'https://m.media-amazon.com/images/I/71y6Ja5P3SL._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61Xi2evX87L._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71cZYEzeNtL._SX679_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71Y8Nn0Q2OL._SY695_.jpg',
    gender: 'Female',
    price: 399 + 499,
    price1: 399,
    price2: 499,
    link1: 'https://amzn.to/4eWPF2v',
    link2: 'https://amzn.to/3RX9TiM',
  },
  {
    id: '25',
    pic: 'https://m.media-amazon.com/images/I/717Qr4ImV+L._SX679_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61OiCRtFDVL._SY741_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/51we+gvJELL.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61RI1m4C39L._SY741_.jpg',
    gender: 'Male',
    price: 299 + 660,
    price1: 299,
    price2: 660,
    link1: 'https://amzn.to/4cVW5x6',
    link2: 'https://amzn.to/3xKTKWZ',
  },
  {
    id: '26',
    pic: 'https://m.media-amazon.com/images/I/71ZIxf36SFL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/71fMgFh8PrL._SY695_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/61TK9v5nBtL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/71fp3yuW3JL._SY695_.jpg',
    gender: 'Female',
    price: 1198,
    price1: 399,
    price2: 799,
    link1: 'https://amzn.to/3XMseTs',
    link2: 'https://amzn.to/4eKQiw0',
  },
  {
    id: '27',
    pic: 'https://m.media-amazon.com/images/I/81+jHAV9ILL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/41ano57ZjmL.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71tY9OHsfUL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/31Q9-jFMWQL.jpg',
    gender: 'Male',
    price: 509 + 372,
    price1: 509,
    price2: 372,
    link1: 'https://amzn.to/3zOdWHP',
    link2: 'https://amzn.to/3VUvrO4',
  },
  {
    id: '28',
    pic: 'https://m.media-amazon.com/images/I/91+XegkkiUL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/51c9eOI6Z0L._SX679_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/91elvgOBXGL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51oYH3XeyCL._SX679_.jpg',
    gender: 'Female',
    price: 549 + 237,
    price1: 549,
    price2: 237,
    link1: 'https://amzn.to/3S1l8a3',
    link2: 'https://amzn.to/3WkcaHv',
  },
  {
    id: '29',
    pic: 'https://m.media-amazon.com/images/I/81oAUOgycVL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61PGRfoJCZL._SY575_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71Ksv2KvIqL._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/51IuUz+emvL.jpg',
    gender: 'Male',
    price: 489 + 194,
    price1: 489,
    price2: 194,
    link1: 'https://amzn.to/4chysOW',
    link2: 'https://amzn.to/3LXZwrv',
  },
  {
    id: '30',
    pic: 'https://m.media-amazon.com/images/I/71YxyRh48EL._SY741_.jpg',
    pic1: 'https://m.media-amazon.com/images/I/61IpylrYyCL._SY575_.jpg',
    pic2: 'https://m.media-amazon.com/images/I/71nfvdHk56L._SY741_.jpg',
    pic3: 'https://m.media-amazon.com/images/I/61oR0ylUNTL._SY575_.jpg',
    gender: 'Female',
    price: 449 + 198,
    price1: 449,
    price2: 198,
    link1: 'https://amzn.to/3Ahu456',
    link2: 'https://amzn.to/3YHYoA9',
  },
]

export default cottageItems
